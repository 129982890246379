import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';


function Square(props) {
    if (props.hightlight) {
        return (
            <button className="square" onClick={props.onClick}>
                <mark>{props.value}</mark>
            </button>
        );
    }
    return (
        <button className="square" onClick={props.onClick}>
            {props.value}
        </button>
    );
}

class Board extends React.Component {
    renderSquare(i) {
        return <Square value={this.props.squares[i]?.value}
                       onClick={() => this.props.onClick(i)}
                       hightlight={this.props.squares[i]?.highlight}
        />;
    }

    render() {
        const rowLength = 3;
        const columnLength = 3;

        const row = ((startingPoint) => {
            const items = [];
            for (let i = 0; i < columnLength; i++) {
                items.push(<span key={i}>{this.renderSquare(startingPoint + i)}</span>)
            }
            return (
                <div key={startingPoint} className="board-row">
                    {items}
                </div>
            )
        });

        const rows = [];
        for (let i = 0; i < rowLength; i++) {
            rows.push(<span key={i}>{row(columnLength * i)}</span>)
        }

        return (
            <div>{rows}</div>
        );
    }
}

class Game extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            history: [{
                squares: Array(9).fill(null),
            }],
            stepNumber: 0,
            xIsNext: true,
            orderIsAsc: true,
        };
    }

    handleClick(i) {
        const history = this.state.history.slice(0, this.state.stepNumber + 1);
        const current = history[history.length - 1];
        const squares = current.squares.slice();
        if (calculateWinner(squares) || squares[i]) {
            return;
        }
        squares[i] = {value: this.state.xIsNext ? 'X' : 'O', highlight: false};
        this.setState({
            history: history.concat([{
                squares: squares,
                move: getCoordinates(i),
            }]),
            stepNumber: history.length,
            xIsNext: !this.state.xIsNext,
        });
    }

    jumpTo(step) {
        this.setState({
            stepNumber: step,
            xIsNext: (step %2) === 0,
        })
    }

    toggleOrder() {
        this.setState({
            orderIsAsc: !this.state.orderIsAsc,
        });
    }

    render() {
        const history = this.state.history;
        const current = history[this.state.stepNumber];

        const moves = history.map((step, move) => {
            const inputSymbol = (move %2) === 0 ? 'O' : 'X';
            const desc = move ?
                `Go to #${move} (${inputSymbol} at ${step.move.x},${step.move.y})` :
                'Go to game start';
            const style = this.state.stepNumber === move ? {'fontWeight': 'bold'} : {'fontWeight': 'normal'};
            return (
                <li key={move}>
                    <button style={style}
                            onClick={() => this.jumpTo(move)}>{desc}</button>
                </li>
            )
        });

        const winCells = calculateWinner(current.squares);
        const isATie = winCells ? false : isTie(current.squares);

        const quote = (() => {
            if (winCells) {
                return  winningQuotes[Math.floor(Math.random() * winningQuotes.length)];
            }
            if (isATie) {
                return  tieQuotes[Math.floor(Math.random() * tieQuotes.length)];
            }
            return null;
        })();

        const status = ((winnerCells = winCells, xIsNext = this.state.xIsNext) => {
            if (winnerCells) {
                for(let cell of winnerCells) {
                    current.squares[cell] = {...current.squares[cell], highlight: true};
                }
                return 'Winner: ' + winnerCells;
            } else {
                return 'Next Player ' + (xIsNext ? 'X' : 'O')
            }
        })();
        const order = this.state.orderIsAsc ? 'Asc' : 'Desc';

        return (
            <div className="game">
                <div className="game-board">
                    <Board squares={current.squares}
                           onClick={(i) => this.handleClick(i)}
                    />
                </div>
                <div className="game-info">
                    <div>{status}</div>
                    <button onClick={() => this.toggleOrder()}>{order}</button>
                    <ol>{this.state.orderIsAsc ? moves : moves.reverse()}</ol>
                </div>
                <div>{quote}</div>
            </div>
        );
    }
}

// ========================================

ReactDOM.render(
    <Game/>,
    document.getElementById('root')
);

function calculateWinner(squares) {
    const lines = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
        const [a, b, c] = lines[i];
        if (squares[a]?.value && squares[a]?.value === squares[b]?.value && squares[a]?.value === squares[c]?.value) {
            return lines[i];
        }
    }
    return null;
}

function isTie(squares) {
    return squares.find(square => square === null) === undefined;
}

const getCoordinates = position => {
    switch (position) {
        case 0:
            return { x: 0, y: 0 };
        case 1:
            return { x: 1, y: 0 };
        case 2:
            return { x: 2, y: 0 };
        case 3:
            return { x: 0, y: 1 };
        case 4:
            return { x: 1, y: 1 };
        case 5:
            return { x: 2, y: 1 };
        case 6:
            return { x: 0, y: 2 };
        case 7:
            return { x: 1, y: 2 };
        case 8:
            return { x: 2, y: 2 };
        default:
            throw position;
    }
};

const winningQuotes = ['Ο Μάρκος είναι κλαψιάρης',
    'Ο Γιώργος είναι πανέξυπνος και καταπληκτικός',
    'Η Μικαέλλα δεν μαγειρεύει καλά',
    'Ο Μάρκος είναι καπετάν φασαρίας',
    'Η Μιρσύνη δεν είναι καλή στο powerpoint',
    'Ο Γιώργος είναι σεμνός και ταπεινός',
    'Η Μικαέλλα βρωμάει τσιγάρο, όλη μέρα, κάθε μέρα',
    'Μη σου τύχει Μάρκος φάντασμα, έχασες',
    'Η Μιρσύνη έχει πάντα αρνητική βαθμολογία στο wizard',
    'Η Μιρσύνη όλο ντρέπεται'];

const tieQuotes = ['Καλά έπαιξες τόση ώρα για αυτό;',
    'Πωωωωω έχει καταντήσει βαρετό',
    'Είπαμε η πρώτη κίνηση είναι πάντα στο κέντρο',
    'Δεν πάτε να παίξετε κάτι άλλο, αφού δεν το\'χετε',
    'Έλεος',
    'Δε σας αντέχω άλλο',
    'Θα πάτε από τις 8 για ύπνο σήμερα',
    'Το ξέρετε ότι σκοπός του παιχνιδιού είναι\n η νίκη και όχι η ισοπαλία έτσι;',
    'Μήπως να το γυρίσετε στην κρεμάλα μπας δούμε άσπρη μέρα;',
    'Booooooooorrrrriiiiiiinngggg',
    'Μήπως πατάτε στην τύχη τα κουμπιά;',
    'Πως φαίνεται ότι τώρα παίζει η Μιρσύνη...',
    'Αν ξαναπάτε ισοπαλία θα σταματήσω να παίζω'];
